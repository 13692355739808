<template>
  <div class="mb-3">
    <cms-content class="cms-content" v-html="block.fields.text">
    </cms-content>
  </div>
</template>

<script>
import CmsContent from '../CmsContent.vue';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  components: {
    CmsContent,
  },
};
</script>
