<template>
  <div class="cms-content mb-3">
    <blockquote v-html="fields.text" />
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fields() {
      return this.block.fields;
    },
  },
};
</script>
