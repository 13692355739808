<template>
  <div class="cms-content">
    <slot />
  </div>
</template>

<style>
.cms-content > * {
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.cms-content > *:first-child {
  margin-top: 0;
}

.cms-content > *:last-child,
.cms-content > *:last-child > *:last-child {
  margin-bottom: 0;
}

.cms-content img {
  display: block;
  max-width: 100%;
}

.cms-content img,
.cms-content .embed-wrap,
.cms-content > iframe {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.cms-content h1,
.cms-content h2,
.cms-content h3,
.cms-content h4 {
  margin-top: 1.75rem;
}

.cms-content a {
  text-decoration: underline;
  color: #111;
}

.cms-content a:hover {
  color: var(--primary);
}

.cms-content ul {
  padding-left: 0;
  list-style: none;
}

.cms-content ul li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: .375rem;
}

.cms-content ul li:before {
  content: '';
  width: .875rem;
  height: .875rem;
  border-radius: 50%;
  position: absolute;
  top: .25em;
  left: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'%3E%3Cpath d='M11.765,8.132A1.679,1.679,0,0,1,9.9,9.972c-.948-.046-1.607-.41-2.28-.41l-.028.013c-.673,0-1.332.376-2.28.422A1.66,1.66,0,0,1,3.441,8.182C3.5,6.91,4.668,6.817,5.666,5.314A2.241,2.241,0,0,1,7.589,4.1h.028A2.249,2.249,0,0,1,9.54,5.314C10.533,6.819,11.71,6.86,11.765,8.132ZM5.9,3.528A1.6,1.6,0,0,0,7.255,1.67,1.719,1.719,0,0,0,5.574,0,1.6,1.6,0,0,0,4.215,1.861,1.719,1.719,0,0,0,5.9,3.528Zm3.182.017a1.9,1.9,0,0,0,2.053-1.387A1.5,1.5,0,0,0,10.214.1,1.9,1.9,0,0,0,8.161,1.488,1.5,1.5,0,0,0,9.078,3.544Zm3.8-.761a1.65,1.65,0,0,0-1.788,1.208,1.308,1.308,0,0,0,.8,1.791,1.65,1.65,0,0,0,1.788-1.208A1.308,1.308,0,0,0,12.879,2.783ZM4.448,3.991A1.65,1.65,0,0,0,2.661,2.783a1.308,1.308,0,0,0-.8,1.791A1.65,1.65,0,0,0,3.649,5.782,1.308,1.308,0,0,0,4.448,3.991Z' transform='translate(-1.77 0)' fill='%239ac40d'/%3E%3C/svg%3E") no-repeat center center;
  background-size: 100% auto;
}

.cms-content ol {
  counter-reset: content-list;
}

.cms-content ol li {
  counter-increment: content-list;
  position: relative;
  margin-bottom: 1.125rem;
  padding-left: 3rem;
}

.cms-content ol li:before {
  content: counter(content-list);
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: -.375em;
  left: 0;
  background-color: var(--purple);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 2.25rem;
  font-weight: 700;
}

.cms-content blockquote {
  margin: 1.5rem 0;
  padding-left: 1.25rem;
  position: relative;
  border-left: 1px dashed var(--purple);
  color: var(--purple);
  font-size: 1.25rem;
}

.cms-content blockquote > * {
  margin-top: 1rem;
  margin-bottom: 0;
}

.cms-content blockquote > *:last-child {
  margin-top: 0;
}

.cms-content blockquote footer {
  font-size: .77rem;
}
</style>
