<template>
  <div class="mb-3" v-if="block && block.fields && userMediaItemsCount > 2">
    <div class="gif-download p-2 p-md-3">
      <h2 v-if="block.fields.title">
        {{ block.fields.title }}
      </h2>

      <div class="cms-content mb-3" v-if="block.fields.text" v-html="block.fields.text"></div>

      <a class="btn btn--primary" :href="`${backendDomain}/pet-gif`" download target="_blank">
        Download .GIF
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../config';

const { baseApiDomain } = config;

export default {
  props: {
    block: Object,
  },

  data() {
    return {
      backendDomain: baseApiDomain,
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),

    userMediaItemsCount() {
      if (!this.user || typeof this.user.user_media_item_count === 'undefined') {
        return 0;
      }

      return this.user.user_media_item_count;
    },
  },
};
</script>

<style scoped>
.gif-download {
  background-color: #fce3e3;
  border-radius: .875rem;
}
</style>
