<template>
  <div v-if="mediaItem" class="image-block mb-3">
    <img
      :src="mediaItem.url"
      :alt="mediaItem.alt"
      width="1000"
      class="image-block__image"
    >
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fields() {
      return this.block.fields;
    },

    mediaItem() {
      return typeof this.fields.media !== 'undefined' && Array.isArray(this.fields.media)
        ? this.fields.media[0]
        : null;
    },
  },
};
</script>

<style>
.image-block {
  overflow: hidden;
  border-radius: .875rem;
}

.image-block__image {
  width: 100%;
  display: block;
}
</style>
