<template>
  <div class="blocks">
    <component
      :is="block.type"
      v-for="block in blocks"
      :key="block.id"
      :block="block"
    />
  </div>
</template>

<script>
import Content from './Blocks/Content.vue';
import Quote from './Blocks/Quote.vue';
import Image from './Blocks/Image.vue';
import Video from './Blocks/Video.vue';
import Competition from './Blocks/Competition.vue';
import GifDownload from './Blocks/GifDownload.vue';

export default {
  props: {
    blocks: {
      type: Array,
      required: true,
    },
  },

  components: {
    Content,
    Image,
    Quote,
    Video,
    Competition,
    GifDownload,
  },
};
</script>
