<template>
  <div class="mb-3" v-if="competition && user">
    <!-- Title -->
    <h2 class="mb-1" v-if="competitionTranslation.title">
      {{ competitionTranslation.title }}
    </h2>

    <!-- Description -->
    <div
      v-if="competitionTranslation.description"
      class="cms-content mb-2"
      v-html="competitionTranslation.description"
    ></div>

    <!-- Success message -->
    <div v-if="answerStored">
      <div class="competition__success no-last-margin mb-2" v-html="completeText"></div>

      <div v-if="competition.type === 'poll' && pollResults">
        <div v-for="(count, title) in pollResults.results" :key="title" class="mb-2 poll-result">
          <div class="d-flex">
            <strong>{{ title }}:</strong> <div class="ml-auto">{{ count }}</div>
          </div>

          <div class="poll-result__bar">
            <div
              class="poll-result__bar-inner"
              :style="{ width: `${(count / pollResults.total) * 100}%` }"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Questions -->
    <div v-if="!answerStored">
      <!-- Poll/quiz -->
      <div v-if="competition.type === 'poll' || competition.type === 'quiz'">
        <div v-for="answer in answers" :key="answer.id" class="mb-2">
          <input
            class="custom-checkbox"
            type="radio"
            :name="`competition-${competition.id}`"
            :id="`answer-${answer.id}`"
            :value="answer.id"
            v-model="selectedAnswer"
          >
          <label :for="`answer-${answer.id}`">
            {{ answer.title }}
          </label>
        </div>
      </div>

      <!-- Message -->
      <div v-if="competition.type === 'message'" class="mb-2">
        <label class="font-weight-bold mb-1 d-block" for="answer">Bericht</label>
        <textarea
          class="form-control"
          :name="`competition-${competition.id}`"
          id="answer"
          rows="4"
          maxlength="1000"
          v-model="selectedAnswer"
        ></textarea>
      </div>

      <!-- Photo -->
      <div v-if="competition.type === 'photo'">
        <photo-upload
          @uploaded="onImageUploaded"
          :image-upload-props="{
            userId: user.user.id,
            petId: activePet.id,
            competitionId: competition.id,
            hasOverlay: competition.has_image_overlay,
          }"
        />
      </div>

      <button
        v-if="competition.type !== 'photo'"
        class="btn btn--primary"
        type="button"
        @click="onSubmitCompetition"
        :disabled="storeCompetitionLoadingState === 'loading'"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PhotoUpload from '../PhotoUpload.vue';

export default {
  components: {
    PhotoUpload,
  },

  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedAnswer: null,
      pollResults: null,
      answerStored: false,
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'activePet',
      'storeCompetitionLoadingState',
    ]),

    competition() {
      if (typeof this.block.fields === 'undefined' || typeof this.block.fields.competition === 'undefined') {
        return null;
      }

      return this.block.fields.competition;
    },

    competitionTranslation() {
      if (!this.competition || typeof this.competition.translation === 'undefined') {
        return null;
      }

      return this.competition.translation;
    },

    answers() {
      if (!this.competition || typeof this.competition.competition_answers === 'undefined') {
        return null;
      }

      return this.competition.competition_answers;
    },

    buttonText() {
      if (
        !this.competitionTranslation
        || typeof this.competitionTranslation.button_text === 'undefined'
        || !this.competitionTranslation.button_text
      ) {
        return 'Deelnemen';
      }

      return this.competitionTranslation.button_text;
    },

    completeText() {
      if (
        !this.competitionTranslation
        || typeof this.competitionTranslation.complete_text === 'undefined'
        || !this.competitionTranslation.complete_text
      ) {
        return 'Bedankt voor je deelname!';
      }

      return this.competitionTranslation.complete_text;
    },
  },

  methods: {
    onSubmitCompetition() {
      this.$store.dispatch('storeCompetition', {
        type: this.competition.type,
        competition_id: this.competition.id,
        answer: this.selectedAnswer,
      })
        .then(({ data }) => {
          if (this.competition.type === 'poll') {
            this.pollResults = data;
          }

          this.answerStored = true;
        });
    },

    onImageUploaded() {
      this.answerStored = true;
    },
  },
};
</script>

<style>
.poll-result__bar {
  height: .5rem;
  border-radius: .5rem;
  background-color: #eee;
  margin-top: .25rem;
}

.poll-result__bar-inner {
  background-color: var(--secondary);
  height: 100%;
  border-radius: .5rem;
}

.competition__success {
  background-color: var(--secondary);
  color: #fff;
  padding: 1rem;
  border-radius: .375rem;
}

.competition__success > * {
  color: #fff;
}
</style>
