<template>
  <div class="mb-3">
    <video-embed
      :src="fields.url"
      :params="{ playsinline: 1, rel: 0, modestbranding: 1 }"
    ></video-embed>
  </div>
</template>

<script>
import Embed from 'v-video-embed/src/embed.vue';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  components: {
    VideoEmbed: Embed,
  },

  computed: {
    fields() {
      return this.block.fields;
    },
  },
};
</script>

<style>
.embed-responsive {
  position: relative;
  padding-bottom: 56.25%;
  border-radius: .875rem;
  overflow: hidden;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
